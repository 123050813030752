/* eslint-disable @next/next/no-img-element */
import React, { useState } from 'react';
import Slider from 'react-slick';
import Image from 'next/image';
import { mediaQueries, Text, Spacing } from '@reservamos/elements';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { css, useTheme } from '@emotion/react';
import { useTranslation } from 'next-i18next';
import LayoutContainer from '../../atoms/LayoutContainer';
import { firstLayer } from '../../../src/tokens/zindexes';
import assetUrl from '../../../utils/assetUrl';
import {
  sprinterAmenities,
  busAmenities,
} from '../../../constants/sliderAmenities';

const SliderRB = ({ type = 'roll-bits', images = [] }) => {
  const [nav1, setNav1] = useState(null);
  const { colors } = useTheme();
  const { t } = useTranslation('common');

  const buttonStyles = css`
    height: 30px;
    width: 30px;
    ${mediaQueries.small} {
      height: 20px;
      width: 20px;
    }
  `;

  const wrapper = css`
    background: url(${assetUrl('main/bg-slider.png')});
    background-size: cover;
    padding: 30px 0;
    border-bottom: 4px solid ${colors.primary};

    ${mediaQueries.small} {
      padding: 10px 0;
    }

    button::before,
    button::after {
      opacity: 0.8;
      font-size: 30px;
      color: ${colors.primary};

      ${mediaQueries.small} {
        font-size: 20px;
      }
    }

    .slick-prev {
      left: 10px;
      z-index: ${firstLayer};
      ${buttonStyles};
    }

    .slick-next {
      right: 20px;
      ${buttonStyles};
      ${mediaQueries.small} {
        right: 1px;
      }
    }
  `;

  const customWrapper = css`
    padding: 0 30px;
    max-width: 100%;
    margin: 0;

    .slick-slide {
      padding: 0 5px;
    }

    .slick-list {
      margin: 0 -5px;
    }

    button::before,
    button::after {
      opacity: 0.8;
      font-size: 30px;
      color: ${colors.primary};
    }

    .slick-prev {
      left: -25px;
      z-index: ${firstLayer};
    }

    .slick-next {
      right: -25px;
    }

    img {
      width: 100%;
      height: auto;
      max-height: 600px;
      object-fit: contain;
      border-radius: 12px;
      background-color: #fff;
    }

    ${mediaQueries.small} {
      padding: 0 25px;

      .slick-prev {
        left: -20px;
      }

      .slick-next {
        right: -20px;
      }

      img {
        max-height: 400px;
      }
    }
  `;

  const imageWrapper = css`
    max-height: 300px;
    min-height: 300px;
    object-fit: cover;

    ${mediaQueries.small} {
      max-height: 200px;
      min-height: 200px;
    }
  `;

  const renderRollBitsContent = () => (
    <Slider asNavFor={nav1} ref={(slider) => setNav1(slider)}>
      <div>
        <Spacing size="XL" flexGrow isResponsive>
          <div css={imageWrapper}>
            <Image
              src={assetUrl('main/mercedez-benz.webp')}
              width={465}
              height={300}
              alt="Mercedez Benz"
            />
          </div>
          <Spacing vertical size="L">
            <Text size="XXL" color="primary" weight="bold">
              Camioneta Sprinter Mercedez Benz
            </Text>
            <ul>
              {sprinterAmenities.map((amenity) => (
                <li key={amenity}>
                  <Text>{amenity}</Text>
                </li>
              ))}
            </ul>
          </Spacing>
        </Spacing>
      </div>
      <div>
        <Spacing size="XL" flexGrow isResponsive>
          <div css={imageWrapper}>
            <Image
              src={assetUrl('main/irizar-16s.webp')}
              width={465}
              height={300}
              alt="Irizar 16s"
            />
          </div>
          <Spacing vertical size="L">
            <Text size="XXL" color="primary" weight="bold">
              Autobus Irizar 16s
            </Text>
            <ul>
              {busAmenities.map((amenity) => (
                <li key={amenity}>
                  <Text>{amenity}</Text>
                </li>
              ))}
            </ul>
          </Spacing>
        </Spacing>
      </div>
    </Slider>
  );

  const renderCustomContent = () => (
    <div css={customWrapper}>
      <Slider
        dots={false}
        infinite
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        autoplay
        autoplaySpeed={3000}
      >
        {images.map((image) => (
          <div key={image.url}>
            {image.onClick ? (
              <button
                type="button"
                onClick={image.onClick}
                style={{
                  padding: 0,
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
                  width: '100%',
                }}
              >
                <img src={image.url} alt={image.alt || ''} />
              </button>
            ) : (
              <img src={image.url} alt={image.alt || ''} />
            )}
          </div>
        ))}
      </Slider>
    </div>
  );

  return (
    <div css={type === 'custom' ? null : wrapper}>
      <LayoutContainer paddingMobile>
        <Spacing vertical size="L">
          {type === 'roll-bits' && (
            <>
              <Text color="grayStrong" size="XXL" weight="bold">
                {t('knowOurUnits')}
              </Text>
              {renderRollBitsContent()}
            </>
          )}
          {type === 'custom' && renderCustomContent()}
        </Spacing>
      </LayoutContainer>
    </div>
  );
};

SliderRB.propTypes = {
  type: PropTypes.oneOf(['roll-bits', 'custom']),
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string,
    })
  ),
};

SliderRB.defaultProps = {
  type: 'roll-bits',
  images: [],
};

export default SliderRB;
